<template>
  <div>
    <div id="myChart8" :style="{ width: '92%', height: '14vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    drawChart() {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart8"),'dark');

          // 指定图表的配置项和数据
          
          let option = {
            tooltip: {
              trigger: 'axis'
            },
            grid: {
                    top: '20%',
                    left: '5%',
                    right: '1%',
                    bottom: '10%',
                    containLabel: true,
              },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [30, 35, 175, 515, 312, 55,611,211,99,149,121,108],
                type: 'line',
                areaStyle: {}
              }
            ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.drawChart();
  },
};
</script>
<style scoped>
</style>