<template>
  <div>
    <div id="myChart2" :style="{ width: '12vw', height: '14.7vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    abnormal(){
                this.$api.bl.cperformance({
                    cname:this.$route.query.cname
                }).then((res)=>{
                    console.log('e2==>',res);
                    if(res.status == 1){
                      this.drawChart(res.obj);
                     
                       
                    }else{
                        this.alertError('e2数据异常')
                    }
                }).catch((error)=>{

                })
            },
    drawChart(data) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart2"),'dark');

          // 指定图表的配置项和数据
          let option = {

            title: [
              {
                subtext: '学生完成度',
                left: '50%',
                top: '75%',
                textAlign: 'center'
              },
            ],
            tooltip: {
              // formatter: '{b}:{d}%',
              formatter(param) {
              return param.name + ":"+' ' + param.percent * 2 + '%';
            },
            trigger: 'item'
          },
      legend: {
        top: '5%',
        left: 'center',
        // doesn't perfectly work with our tricks, disable it
        selectedMode: false
      },
      series: [
        {
          name: '学生完成度',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '70%'],
          // adjust the start angle
          startAngle: 180,
          label: {
            show: true,
            formatter(param) {
              return param.name + ' (' + param.percent * 2 + '%)';
            }
          },
          data: [
            { value: data.num3, name: '完成' },
            { value: data.num4, name: '未完成' },
            {
              // make an record to fill the bottom 50%
              value: data.num3 + data.num4,
              itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          ]
        }
      ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.abnormal();
  },
};
</script>
<style scoped>
</style>