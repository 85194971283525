<template>
  <div>
    <div id="myChart3" :style="{ width: '24vw', height: '14.7vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {

    abnormal(){
      
                this.$api.bl.abnormal({
                    cname:this.$route.query.cname
                }).then((res)=>{
                    console.log('E3==>',res);
                    if(res.status == 1){
                          this.drawChart(res.obj);
                    
                    }else{
                        this.alertError('e3数据异常')
                    }
                }).catch((error)=>{

                })
            },

            
    drawChart(data) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart3"),'dark');

          // 指定图表的配置项和数据
          console.log(123);
          console.log("data",data);
          let option = {
                    tooltip: {
                      formatter: '{b}:{d}%',
                        trigger: 'item'
                    },
                    legend: {
                      top: 'bottom'
                    },
                    toolbox: {
                      show: true,
                    },
                    series: [
                      {
                        name: '数值（百分比）',
                        type: 'pie',
                        radius: [20, 82],
                        center: ['50%', '42%'],
                        roseType: 'area',
                        itemStyle: {
                          borderRadius: 8
                        },
                        // label: {
                        //     show: true,
                        //     formatter : function(params){
                        //       console.log(235);
                        // return params.name + ":" + ((Number(params.value)/a)*100).toFixed(2) + '%'
                        //     }
                        // },
                        
                    data: [ 
                      { value: data[0].value, name: '正常' },
                      { value: data[1].value, name: '边缘' },
                      { value: data[2].value, name: '轻度' },
                      { value: data[3].value, name: '中度' },
                      { value: data[4].value, name: '重度' },
                    ]
     
              }
            ]
     
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.abnormal();
  },
};
</script>
<style scoped>
</style>