<template>
  <div>
    <div id="myChart9" :style="{ width: '98%', height: '14vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
     abnormal(){
                this.$api.bl.svisionDistribution({
                    cname:this.$route.query.cname
                }).then((res)=>{
                    console.log('e9==>',res);
                    if(res.status == 1){
                      this.drawChart(res.obj);
                    }else{
                        this.alertError('e9数据异常')
                    }
                }).catch((error)=>{

                })
            },
    drawChart(data) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart9"),'dark');

          // 指定图表的配置项和数据
          let option = {
            grid: {
                    top: '20%',
                    left: '5%',
                    right: '1%',
                    bottom: '10%',
                    containLabel: true,
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
          xAxis: [

            {
              axisTick: {
                alignWithLabel: true
	        	  },
              type: 'category',
              data: data.title,

          
              axisLabel: {  /*****重点还是在这里哦**///
                // textStyle: {
                //   color: '#7c8893',
                //   fontSize: 12
                // },
                // interval: 0,  
                formatter: function(params) {
                  var newParamsName = ""; // 最终拼接成的字符串
                  var paramsNameNumber = params.length; // 实际标签的个数
                  var provideNumber = 6; // 每行能显示的字的个数
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                  /**
                   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                   */
                  // 条件等同于rowNumber>1
                  if (paramsNameNumber > provideNumber) {
                    /** 循环每一行,p表示行 */
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = ""; // 表示每一次截取的字符串
                      var start = p * provideNumber; // 开始截取的位置
                      var end = start + provideNumber; // 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                        // 最后一次不换行
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        // 每一次拼接字符串并换行
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr; // 最终拼成的字符串
                    }
          
                  } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                  }
                  //将最终的字符串返回
                  return newParamsName
                }
              },
              // axisLabel: {
              //    rotate: 10
              // }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              
              name: '正常',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: data.data1
            },
            {
              
              name: '边缘',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: data.data2
            },
            {
              color:"#2ecc71",
              name: '轻度',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: data.data3
            },
            {
              color:"#f9ca24",
              name: '中度',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: data.data4
            },
            {
              color:"#e74c3c",
              name: '重度',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: data.data5
            },
        ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.abnormal();
  },
};
</script>
<style scoped>
</style>