<template>
  <div>
    <div id="myChart6" :style="{ width: '20vw', height: '14vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    drawChart() {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart6"),'dark');

          // 指定图表的配置项和数据
          let option = {
              tooltip: {
                trigger: 'axis',
              },
              legend: {},
              grid: {
                    top: '15%',
                    left: '1%',
                    right: '1%',
                    bottom: '10%',
                    containLabel: true,
              },
              xAxis: {
                show: false,
                // type: 'value',
              },
              yAxis: {
                type: 'category',
                data: [  '电脑验光', '眼轴检查', '视功能检查', '眼底检查','其他数据'],     
                        axisTick: {
                    show: false//不显示坐标轴刻度线
                },
                axisLine: {
                    show: false,//不显示坐标轴线
                },  
              },
           
              series: [
                {
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  data: [ 100,
                  {
                          value: 75,
                          itemStyle: {
                            color: '#ff5e57'
                          }
                        },
                        {
                          value: 22,
                          itemStyle: {
                            color: '#f9ca24'
                          }
                        },
                        {
                          value: 18,
                          itemStyle: {
                            color: '#0fbcf9'
                          }
                        },
                        
                        {
                          value: 69,
                          itemStyle: {
                            color: '#7bed9f'
                          }
                        },
                  ]
                }
              ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.drawChart();
  },
};
</script>
<style scoped>
</style>