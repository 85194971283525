<template>
  <div>
    <div id="myChart4" :style="{ width: '95%', height: '10vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    drawChart() {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart4"),'dark');

          // 指定图表的配置项和数据
          let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
                    top: '5%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true,
              },
              xAxis: {
                      type: 'category',
                      data: ['离焦矫正', '红光治疗', '雾视训练', '灯光改造', '视功能', '户外运动', '叶黄素']
                    },
                    yAxis: {
                      type: 'value'
                    },
                    series: [
                      {
                        data: [125, 
                        {
                          value: 20,
                          itemStyle: {
                            color: '#ff5e57'
                          }
                        },
                        {
                          value: 35,
                          itemStyle: {
                            color: '#f9ca24'
                          }
                        },
                        {
                          value: 100,
                          itemStyle: {
                            color: '#0fbcf9'
                          }
                        },
                        
                        {
                          value: 78,
                          itemStyle: {
                            color: '#ff9ff3'
                          }
                        },
                        {
                          value: 696,
                          itemStyle: {
                            color: '#7bed9f'
                          }
                        },
                        
                        {
                          value: 322,
                          itemStyle: {
                            color: '#27ae60'
                          }
                        },
                        
                        
                        
                        
                        35, 100, 78],
                        
                        type: 'bar',
                        backgroundStyle: {
                          color: 'rgba(180, 180, 180, 0.2)'
                        }
                      }
                    ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.drawChart();
  },
};
</script>
<style scoped>
</style>