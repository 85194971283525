<template>
  <div>
    <div id="myChart5" :style="{ width: '98%', height: '14vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    drawChart() {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart5"),'dark');

          // 指定图表的配置项和数据
          let option = {
              tooltip: {
                trigger: 'item'
              },
              legend: {
                data: ['矫正方案雷达图'],
                left: '10%'
              },
              radar: {
                // shape: 'circle',
                indicator: [
                  { name: '未戴镜', max: 3000 },
                  { name: 'ok镜', max: 3000 },
                  { name: '功能镜', max: 3000 },
                  { name: '其他', max: 3000 },
                  { name: '离焦软镜', max: 3000 },
                  { name: '单光镜', max: 3000 }
                ]
              },
              series: [
                {
                  name: '矫正方案',
                  type: 'radar',
                  data: [
                    {
                      value: [2698, 15, 1233, 510, 893, 1809],
                      // name: '矫正方案雷达图'
                    }
                   
                  ]
                }
              ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.drawChart();
  },
};
</script>
<style scoped>
</style>