<template>
  <div>
    <div id="myChart10" :style="{ width: '98%', height: '14vw' }"></div>
  </div>
</template>


<script>
var echarts = require("echarts");

export default {
  props: ["sid"],
  methods: {
    abnormal(){
      console.log("this.$route.query.cname==>",this.$route.query.cname);
                this.$api.bl.clvision({
                    cname:this.$route.query.cname
                }).then((res)=>{
                    console.log('e10==>',res);
                    if(res.status == 1){
                      this.drawChart(res.obj);
                     
                       
                    }else{
                        this.alertError('e10数据异常')
                    }
                }).catch((error)=>{

                })
            },
    drawChart(data) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById("myChart10"),'dark');

          // 指定图表的配置项和数据
          let option = {
            tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['正常', '边缘', '轻度', '中度', '重度']
  },
  grid: {
    left: '3%',
    right: '3%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: data.title
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '正常',
      type: 'line',
      stack: 'Total',
      data: data.data[0].data
    },
    {
      name: '边缘',
      type: 'line',
      stack: 'Total',
      data:data.data[1].data
    },
    {
      color:"#2ecc71",
      name: '轻度',
      type: 'line',
      stack: 'Total',
      data: data.data[2].data
    },
    {
      color:"#f9ca24",
      name: '中度',
      type: 'line',
      stack: 'Total',
      data: data.data[3].data
    },
    {
      color:"#e74c3c",
      name: '重度',
      type: 'line',
      stack: 'Total',
      data: data.data[4].data
    }
  ]
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
  },
  mounted() {
    this.abnormal();
  },
};
</script>
<style scoped>
</style>