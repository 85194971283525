<template>
    <div class="main">
        <header>第二师教育局学生视力保护监测中心</header>
        <!-- <header>第二师铁门关市教育局学生视力档案管理系统</header> -->
        <!-- <div style="font-size: 18px;color: #fff;text-align: right;margin: 0 20px 10px 0;">{{ date }}</div> -->
        <div class="upper">
            <div class="left">
                <div class="left_up">
                    <span style="margin-left: .5vw;">本期筛查完成度</span>
                    <div class="hang1">
                        <E1 :sid="sid"></E1>
                        <E2 :sid="sid"></E2>
                    </div>
                </div>
                <div class="left_low">
                    <span style="margin-left: .5vw;">整体视力不良占比</span>
                    <div class="hang1">
                        <E3 :sid="sid"></E3>
                    </div>

                </div>
            </div>
            <div class="middle">
                <div class="nav">
                    <el-row type="flex" justify="space-around">
                        <el-col :span="4">
                            <div class="nav_item ">
                                <span class="span_top">学校数</span>
                                <span class="span_bottom">{{ statisticsData.num }}</span>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="nav_item ">
                                <span class="span_top">学生数</span>
                                <span class="span_bottom">{{ statisticsData.num1 }}</span>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="nav_item ">
                                <span class="span_top">筛查次数</span>
                                <span class="span_bottom">{{ statisticsData.num2 }}</span>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="nav_item">
                                <span class="span_top">家长领取次数</span>
                                <span class="span_bottom">{{statisticsData.num3}}</span>
                                <!-- <span class="span_bottom">94781</span> -->
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="nav_item">
                                <span class="span_top">家长上传档案数</span>
                                <span class="span_bottom">{{statisticsData.num4}}</span>
                                <!-- <span class="span_bottom">3215</span> -->
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="mmiddle">
                    <div class="middle_left">
                        <div>屈光变化人数</div>
                        <div class="hang">
                            <div class="red nav_item" style="margin: 0 10px;">
                                <span class="span_top1">增加</span>
                                <span class="span_bottom1">26</span>
                            </div>

                            <div class=" nav_item yellow" style="margin: 0 10px;">
                                <span class="span_top1">不变</span>
                                <span class="span_bottom1">26</span>
                            </div>

                            <div class="green nav_item" style="margin: 0 10px;">
                                <span class="span_top1">减少</span>
                                <span class="span_bottom1">26</span>
                            </div>
                        </div>
                    </div>
                    <div class="middle_right">
                        <div>综合防控人数</div>
                        <div>
                            <E4 :sid="sid"></E4>
                        </div>
                    </div>
                </div>
                <div class="tmiddle">
                    <div class="tmiddle_left">
                        <div>家长上传档案类型</div>
                        <div>
                            <E6 :sid="sid" style="margin-left: 2vw;"></E6>
                        </div>
                    </div>
                    <div class="tmiddle_right">
                        <div>矫正方式占比</div>
                        <div>
                            <E5 :sid="sid"></E5>
                        </div>
                    </div>
                </div>

            </div>
            <div class="right">
                <div class="left_up">
                    <div style="margin-left: .5vw;">家长复查视力次数</div>
                    <div>
                        <E7 :sid="sid"></E7>
                    </div>
                </div>
                <div class="left_low">
                    <div style="margin-left: .5vw;">科普完成人数</div>
                    <div>
                        <E8 :sid="sid"></E8>
                    </div>
                </div>
            </div>
        </div>



        <div class="lower">
            <div class="lower1">
                <span style="margin-left: .5vw;">全区学校视力状况</span>
                <div>
                    <E9 :sid="sid"></E9>
                </div>


            </div>
            <div class="lower1">
                <span style="margin-left: .5vw;">各年级视力不良占比</span>
                <div>
                    <E10 :sid="sid"></E10>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import E1 from "./E1.vue";
import E2 from "./E2.vue";
import E3 from "./E3.vue";
import E4 from "./E4.vue";
import E5 from "./E5.vue";
import E6 from "./E6.vue";
import E7 from "./E7.vue";
import E8 from "./E8.vue";
import E9 from "./E9.vue";
import E10 from "./E10.vue";
export default {
    components: { E1, E2, E3, E4, E5, E6, E7, E8, E9, E10 },
    data() {
        return {
            sid: "",
            statisticsData: {},
            date: '',
        }
    },
    methods: {
        //统计数据
        statistics() {
            this.$api.bl.statisticsData({
                cname: this.$route.query.cname
            }).then((res) => {
                console.log('statisticsData==>', res);
                if (res.status == 1) {
                    console.log(res.obj);
                    this.statisticsData = res.obj
                } else {
                    console.log('统计数据异常')
                }
            }).catch((error) => {

            })
        },
    },
    mounted() {
        this.statistics();

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份从0开始，需要加1
        const day = currentDate.getDate();
        this.date = `${year}年${month}月${day}日`
    },

}
</script>

<style scoped>
.main {
    padding: 0.1vw 0.2vw;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(26, 27, 48, 1)
}

header {
    text-align: center;
    font-size: 1.82vw;
    color: rgb(254, 255, 255);
    line-height: 5vw;
    height: 5vw;
}

.hang {
    display: flex;
    justify-content: space-around;
    padding: 0 5vw;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hang1 {
    display: flex;
    justify-content: space-between;
}

.upper {
    display: flex;
    justify-content: space-between;
    height: 33.86vw;
    color: white;
}

.left {
    width: 25%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.left_up {
    padding-top: .5vw;
    height: 16.5vw;
    background-color: rgba(16, 12, 42, 1);
}

.left_low {
    padding-top: .5vw;
    height: 16.5vw;
    background-color: rgba(16, 12, 42, 1);
}

.middle {
    margin: 0 1vw;
    width: 47.8vw;
    height: 34vw;
    background-color: rgba(16, 12, 42, 1);
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    height: 34vw;
}

.nav {
    padding: 0px 30px 20px 30px;
}

.nav_item {
    border-radius: 0.47vw;
    opacity: 0.92;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 4.43vw;
}

.span_top {
    font-size: .8vw;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 5px;
}

.span_top1 {
    font-size: .8vw;
}

.span_bottom {
    font-size: 36px;
    color: rgba(74, 146, 255, 1);
}

.span_bottom1 {
    font-size: 36px;
}

.mmiddle {
    height: 12.5vw;
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
}

.tmiddle {
    text-align: center;
    display: flex;
    color: white;
}

.red {
    color: #E23535
}

.yellow {
    color: #B1E623
}

.green {
    color: #04B152
}

.middle_left {
    height: 10vw;
    width: 50%;
}

.tmiddle_left {
    width: 50%;
}

.middle_right {
    height: 12vw;
    width: 50%;

}

.tmiddle_right {
    width: 50%;
}

.lower {
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
}

.lower1 {
    padding-top: .5vw;
    background-color: rgba(16, 12, 42, 1);
    border-radius: 6px;
    height: 16.57vw;
    width: 49.4vw;
    color: white;
}
</style>